import axios, { endpoints } from 'src/utils/axios';
import { toast } from 'src/components/snackbar';
import { useRouter } from 'src/routes/hooks';

import { setSession } from './utils';
import { STORAGE_KEY } from './constant';

/** **************************************
 * Sign in
 *************************************** */
export const signInWithPassword = async ({ username, password }) => {
  try {
    const params = { username, password };

    const res = await fetch(`https://linduapp.com.br/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(params)
    });

    if (res.ok) {
      // Obtenha o corpo da resposta como JSON
      console.log('Response OK');

      // Acesse os headers
      const headers = res.headers;

      // Exemplo de como pegar o valor de um header específico, como o 'Authorization'
      const authorizationHeader = headers.get('Authorization');

      const accessToken = authorizationHeader;

      const res2 = await fetch(`https://linduapp.com.br/api/cliente/self`, {
        method: "GET",
        headers: {
          "Authorization": accessToken
        }
      })
      const data = await res2.json();

      console.log('TOKEN:', accessToken);
      console.log('AQUIII:', data);

      if (res2.ok) {
        sessionStorage.setItem("lindu_client", accessToken)
        setSession(accessToken);
        toast.success('Login efetuado com sucesso!');
      } else {
        setSession(accessToken);
      }
      console.log('Authorization Header:', authorizationHeader);
    } else {
      throw new Error('Access token not found in response');
    }
  } catch (error) {
    console.error('Error during sign in:', error);
    throw error;
  }
};

/** **************************************
 * Sign up
 *************************************** */
export const signUp = async (params) => {
  try {
    const res = await fetch(`https://linduapp.com.br/api/estabelecimento`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(params)
    });

    // return status code
    return res.status;

    // const { accessToken } = res;

    // if (!accessToken) {
    //   throw new Error('Access token not found in response');
    // }

    // sessionStorage.setItem(STORAGE_KEY, accessToken);
  } catch (error) {
    console.error('Error during sign up:', error);
    throw error;
  }
};

/** **************************************
 * Sign out
 *************************************** */
export const signOut = async () => {
  try {
    await setSession(null);
  } catch (error) {
    console.error('Error during sign out:', error);
    throw error;
  }
};
