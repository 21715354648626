import { Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useAuthContext } from "src/auth/hooks";
import { DashboardContent } from "src/layouts/dashboard";
import { z as zod } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

export default function CreateNewSchedule() {
    const flexProps = { flex: '1 1 auto', display: 'flex', flexDirection: 'column' };

    return (
        <DashboardContent maxWidth="xl" sx={{ ...flexProps }}>
            <Typography variant="h4" gutterBottom>
                Agendamento
            </Typography>
            <Typography variant="body2">
                Confira a programação da sua agenda
            </Typography>

            <Stack spacing={3} sx={{ mt: 5 }} bgcolor="red" width="100%" height="100%">
                <Typography variant="h4" gutterBottom>
                    Selecione o cliente
                </Typography>
            </Stack>
        </DashboardContent>
    );
}